<template>
    <div id="page_gallery" class="page">
        <div class="page_background"></div>
        <div id="page_gallery_content">
            <div id="page_title">선겸이 성장 일기 갤러리</div>
            <div id="area_pictures">
                <div class="gallery_pic" v-for="(pic, index) in pics" :key="pic.url" @click="open_zoom(pic, index)">
                    <img :src="pic.thumb" alt="pic" :width="pic.width" :height="pic.height" />
                    <!-- <div class="pic_title">{{ pic.title }}</div>
                    <div class="pic_description">{{ pic.description }}</div> -->
                </div>
            </div>
        </div>
        <div id="panzoom">
            <div id="panzoom-container">
                <div id="panzoom-element">
                    <img id="zoom_image" src="" alt="zoom_image" @click="close_zoom" />
                    <div id="date_text" style="color: white; font-size: 25px">{{ pic_text }}</div>
                </div>
            </div>
            <div id="button_slide_left" class="button" @click="slide_left()" />
            <div id="button_slide_right" class="button" @click="slide_right()" />
            <div id="button_close" class="button" @click="close_zoom()" />
        </div>
    </div>
</template>
<script>
import $ from "jquery";
import Panzoom from "@panzoom/panzoom";

export default {
    mounted() {
        this.pics = [];
        // $.ajax({
        //     url: "/upload/image.php",
        //     type: "GET",
        //     success: (data) => {
        //         // for (var i = 0; i < 20; i++) {
        //         //     this.pics.push({
        //         //         url: data[i].url,
        //         //         title: data[i].title,
        //         //         description: data[i].thumbnailUrl,
        //         //     });
        //         // }
        //         console.log(data);
        //     },
        //     error: (error) => {
        //         console.log(error);
        //     },
        // });
        $.get("/upload/image.php", (data) => {
            var pics = JSON.parse(data);
            pics.sort((a, b) => String(a.image).localeCompare(String(b.image)));
            // pics.reverse();
            this.pics = pics;
            // this.current_pic_info.url = pics[0].image;
            $("#zoom_image").attr("src", pics[2].image);
            console.log(pics[0]);
        });

        const elem = document.getElementById("panzoom-element");
        this.panzoom = Panzoom(elem, {
            maxScale: 5,
            minScale: 1,
            bounds: true,
            // contain: 'outside',
            boundsPadding: 1,
            beforeWheel: (e) => {
                if (e.ctrlKey) {
                    return true;
                }
                return false;
            },
        });
    },

    methods: {
        gettsfromurl(pic) {
            var ts = pic.image.split("/").pop().split(".")[0];
            console.log(ts);
            var date = new Date(parseInt(ts) * 1000);
            var year = date.getFullYear();
            var month = date.getMonth();
            if(month == 0){
                month = 12;
                year -= 1;
            }
            var day = date.getDate();
            this.pic_text = year + "년 " + month + "월 " + day + "일";
        },
        open_zoom(pic, index) {
            this.current_pic_index = index;
            $("#panzoom").css("display", "flex");
            $("#zoom_image").attr("src", "");
            $("#zoom_image").attr("src", pic.image);

            this.gettsfromurl(pic);

            this.panzoom.reset();

            var window_height = window.innerHeight;
            $("#panzoom-container").css("height", window_height + "px");
        },

        slide_left() {
            if (this.current_pic_index == 0) {
                this.current_pic_index = this.pics.length - 1;
            } else {
                this.current_pic_index -= 1;
            }

            var pic = this.pics[this.current_pic_index];
            $("#zoom_image").attr("src", "");
            $("#zoom_image").attr("src", pic.image);
            this.gettsfromurl(pic);

            this.panzoom.reset();
        },

        slide_right() {
            if (this.current_pic_index == this.pics.length - 1) {
                this.current_pic_index = 0;
            } else {
                this.current_pic_index += 1;
            }
            var pic = this.pics[this.current_pic_index];
            $("#zoom_image").attr("src", "");
            $("#zoom_image").attr("src", pic.image);
            this.gettsfromurl(pic);
            this.panzoom.reset();
        },

        close_zoom() {
            $("#panzoom").css("display", "none");
        },
    },

    data() {
        return {
            pic_text: "",
            panzoom: null,
            pics: [],
            preventScrollEvent: false,
            current_pic_index: 0,
        };
    },
};
</script>
<style lang="scss" scoped>
#panzoom {
    position: fixed;
    left: 0px;
    width: 100vw;
    height: 100vh;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 1000;
    display: none;
    // display: flex;

    #panzoom-container {
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        object-fit: contain;
        width: 100%;
        // height: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: auto;
        margin-bottom: auto;
    }

    div.button {
        position: absolute;
        width: 70px;
        height: 70px;
        background-size: cover;
        filter : drop-shadow(0 0 5px #0008);

        &#button_slide_left {
            left: 0px;
            top: calc(50vh - 25px);
            background-image: url("@/assets/images/arrow_left.svg");
        }
        &#button_slide_right {
            right: 0px;
            top: calc(50vh - 25px);
            background-image: url("@/assets/images/arrow_left.svg");
            transform: rotate(180deg);
        }
        &#button_close {
            right: 0px;
            top: 0px;
            background-image: url("@/assets/images/circle_close.svg");
        }
    }
}
#page_gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    margin-bottom: 50px;
    width: 100vw;
    // background-color: #f3d2cb;
    background-image: url("@/assets/images/background_pattern4.png");
    background-size: 50%;

    #page_title {
        margin-bottom: 10px;
        font-size: 40px;
        color: #000;
        // filter: drop-shadow(0px 0px 5px #000);
    }
    #page_gallery_content {
        margin-top: 30px;
        width: 100vw;
        top: 0;
        left: 0;
        z-index: 1;
        #area_pictures {
            width: 100vw;
            height: fit-content;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;
            img {
                // width: 45vw;
                height: 40vw;
                width: auto;
                margin-top: 10px;
                border-radius: 5px;
                object-fit: contain;
                filter: drop-shadow(0 0 5px #0008);
            }
            padding-bottom: 30px;
        }
    }
    #page_intro_background {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100vw;
        height: 100vh;
        z-index: 0;
        overflow-y: hidden;
        .balloon {
            position: absolute;
            left: 30px;
            top: 110vh;
            width: 20vw;
        }
    }

    #img_sg_main {
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        background-image: url("@/assets/images/sg_main.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 85vw;
        height: 85vw;
        border-radius: 45vw;
        border: 5px solid #fff;
        filter: drop-shadow(0 0 10px #0008);
    }
}
</style>
