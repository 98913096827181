<template>
    <div id="app">
        <MenuTop />
        <PageIntro />
        <PageGreeting />
        <PageMap />
        <PageGallery />

    </div>
</template>

<script>
import PageIntro from "./components/PageIntro.vue";
import PageGreeting from "./components/PageGreeting.vue";
import MenuTop from "./components/MenuTop.vue";
import PageGallery from "./components/PageGallery.vue";
import PageMap from "./components/PageMap.vue";

export default {
    name: "App",
    components: {
        PageIntro,
        MenuTop,
        PageGreeting,
        PageGallery,
        PageMap,
    },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Do+Hyeon&family=Dongle&family=Dongle&family=Nanum+Pen+Scrip&family=Nanum+Pen+Script&family=Noto+Sans+KR:wght@100..900&display=swap");

#app {
    font-family: "Nanum Pen Script", cursive;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background-color: #f3d2cb;
}
</style>

<style lang="scss">
body {
    background-color: #f3d2cb;
    padding: 0;
    margin: 0;
}
div {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
div.page {
    height: fit-content;
    min-height: 100vh;
    // padding-top : 50px;
}

div.page_background {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 0;
    overflow-y: hidden;
    .balloon1 {
        position: absolute;
        left: 30px;
        top: 110vh;
        width: 20vw;
    }
    .balloon2 {
        position: absolute;
        left: 40vw;
        top: 110vh;
        width: 20vw;
    }
    .balloon3 {
        position: absolute;
        left: 80vw;
        top: 110vh;
        width: 20vw;
    }
}
</style>
