<template>
    <div id="page_intro" class="page">
        <div class="page_background">
            <div class="balloon1">
                <img src="@/assets/images/balloon_blue.png" alt="flags" id="img_flags" width="100%" />
            </div>
            <div class="balloon2">
                <img src="@/assets/images/balloon_red.png" alt="flags" id="img_flags" width="80%" />
            </div>
            <div class="balloon3">
                <img src="@/assets/images/balloon_black.png" alt="flags" id="img_flags" width="50%" />
            </div>
        </div>
        <div id="page_intro_content">
            <div id="top">
                <img src="@/assets/images/flag_animation.gif" alt="flags_top" id="img_flags_top" width="100%" />
            </div>
            <div id="text1">선겸이의 첫번째 생일에 초대합니다.</div>
            <div id="img_sg_main" />
            <div id="img_ribbon" />
            <div id="text1">날짜 : 2024년 8월 31일<br />시간 : 오후 3시<br />장소 : 제니스뷔페 수성점 토파즈홀</div>
        </div>
    </div>
</template>
<script>
import $ from "jquery";

export default {
    mounted() {
        const animation_balloon_blue = () => {
            var left = Math.random() * 80;
            $(".balloon1").css("left", left + "vw");
            $(".balloon1").animate({ top: "-50vh" }, 10000, () => {
                $(".balloon1").css("top", "110vh");
                animation_balloon_blue();
            });
        };

        animation_balloon_blue();

        setTimeout(() => {
            const animation_balloon_red = () => {
                var left = Math.random() * 80;
                $(".balloon2").css("left", left + "vw");

                $(".balloon2").animate({ top: "-50vh" }, 15000, () => {
                    $(".balloon2").css("top", "110vh");
                    animation_balloon_red();
                });
            };
            animation_balloon_red();
        }, 1500);

        const animation_balloon_black = () => {
            var left = Math.random() * 80;
            $(".balloon3").css("left", left + "vw");
            $(".balloon3").animate({ top: "-50vh" }, 20000, () => {
                $(".balloon3").css("top", "110vh");
                animation_balloon_black();
            });
        };

        animation_balloon_black();
    },
};
</script>
<style lang="scss" scoped>
#page_intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    // background-color: #f3d2cb;
    background-image: url("@/assets/images/background_pattern2.png");
    background-size: 50%;
    #page_intro_content {
        margin-top: 20px;
        width: 100vw;
        top: 0;
        left: 0;
        z-index: 1;
    }
    #text1 {
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
        margin-top: 30px;
        font-size: 30px;
        text-align: left;
        filter: drop-shadow(2px 2px 5px #f00);
    }
    #img_sg_main {
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        background-image: url("@/assets/images/sg_main.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 85vw;
        height: 85vw;
        border-radius: 45vw;
        border: 5px solid #fff;
        filter: drop-shadow(0 0 10px #0008);
    }
    #img_ribbon {
        margin-top: -20vw;
        margin-left: auto;
        margin-right: auto;
        background-image: url("@/assets/images/ribbon_red.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 90vw;
        height: 30vw;
        filter: drop-shadow(0 0 10px #0008);
    }
}
</style>
