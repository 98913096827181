<template>
    <div id="menu_top">
        <div class="menu_item" @click="on_click_menu('intro')">HOME</div>
        <div class="menu_item" @click="on_click_menu('greeting')">인사말씀</div>
        <div class="menu_item" @click="on_click_menu('map')">찾아오시는 길</div>
        <!-- <div class="menu_item" @click="on_click_menu('intro')">한줄 축하글</div> -->
        <div class="menu_item" @click="on_click_menu('gallery')">갤러리</div>
    </div>
</template>
<script>
import $ from "jquery";

export default {
    mounted() {},

    methods: {
        on_click_menu(element_name) {
            //scroll to the element
            try {
                var element = $("#page_" + element_name);
                var top = element.offset().top;

                $("html").animate({ scrollTop: top }, 500);
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
div#menu_top {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 50px;
    background-color: #000;
    color: #fff;
    align-items: center;
    flex-direction: row;
    z-index: 100;
    div.menu_item {
        --border-info: 1px solid #555;
        width: fit-content;
        height: fit-content;
        padding: 1px 0px;
        border-left: var(--border-info);
        border-right: var(--border-info);
        font-size: 20px;
        flex-grow: 1;

        &:first-child {
            border-left: none;
        }
        &:last-child {
            border-right: none;
        }
    }
}
</style>
