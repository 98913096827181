<template>
    <div id="page_map" class="page">
        <div id="page_content">
            <div id="text1">찾아오시는 길</div>
            <div id="kakaomap_container">
                <KakaoMap ref="kakaomap" />
            </div>
            <div id="buttons">
                <div id="button_navit" @click="startNaviT()">TMAP 실행</div>
                <div id="button_navik" @click="startNaviKaKao()">카카오맵 실행</div>
            </div>
            <div id="text2">
                <span>주소</span>대구 수성구 달구벌대로 2435 두산위브제니스상가 301<br />
                <span>대중교통</span>대구 지하철 1호선 범어역 10번 출구에서 50m <span>연락처</span>엄마 : 010-3111-4329, 아빠 : 010-9211-7463
            </div>
        </div>
    </div>
</template>
<script>
import $ from "jquery";
import KakaoMap from "@/components/KakaoMap.vue";

export default {
    components: {
        KakaoMap,
    },
    mounted() {},
    methods: {
        startNaviT() {
            window.location.href = "tmap://navigate?referrer=com.skt.Tmap&name=두산위브제니스상가&lon=128.628267994973&lat=35.8609892465636&autoclose=true";
        },

        startNaviKaKao() {
            window.location.href = "kakaomap://route?ep=35.8609892465636,128.628267994973&by=CAR";
        },
    },
};
</script>
<style lang="scss" scoped>
#kakaomap_container {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 95vw;
    height: 75vw;
    top: 0;
    left: 0;
    z-index: 1;
}
#page_map {
    font-family: "Notosans", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    // background-color: #f3d2cb;
    background-image: url("@/assets/images/background_pattern5.png");
    background-size: 50%;

    .nickname {
        font-size : 10px;
    }

    #page_content {
        margin-top: 50px;
        width: 100vw;
        top: 0;
        left: 0;
        z-index: 1;
    }
    #text1 {
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
        font-size: 20px;
        font-weight : bold;
        text-align: left;
        color: #000;
        margin-bottom : 10px;
        filter: drop-shadow(0px 0px 5px #000);
    }

    #text2 {
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
        font-size: 15px;
        text-align: left;
        color: #000;
        // filter: drop-shadow(0px 0px 5px #000);
        span {
            margin-top: 15px;
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 5px;
        }
    }
    #buttons {
        flex-direction: row;
        width : 100%;
        justify-content: end;

        div {
            font-family: "Do Hyeon", sans-serif;
            margin-top: 10px;
            margin-right: 2.5vw;
            padding: 0px 20px;
            width: fit-content;
            height: 40px;
            background-color: rgb(69, 136, 237);
            color: #fff;
            font-size: 15px;
            text-align: center;
            line-height: 40px;
            border-radius: 5px;
            cursor: pointer;
            filter: drop-shadow(0px 0px 5px #000);
        }
        #button_navik {
            color: #000;
            background-color: rgb(237, 195, 69);
        }
    }

    #img_sg_main {
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        background-image: url("@/assets/images/sg_main.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 85vw;
        height: 85vw;
        border-radius: 45vw;
        border: 5px solid #fff;
        filter: drop-shadow(0 0 10px #0008);
    }
    #img_ribbon {
        margin-top: -20vw;
        margin-left: auto;
        margin-right: auto;
        background-image: url("@/assets/images/ribbon_red.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 90vw;
        height: 30vw;
        filter: drop-shadow(0 0 10px #0008);
    }
}
</style>
