<template>
    <div ref="kakaomapcontainer" id="kakaomapcontainer"></div>
</template>
<script>
var kakao = null;
var kakao_retry_count = 0;

export default {
    mounted() {
        console.log("mounted");
        this.load_kakaomap();
    },

    methods: {
        refresh() {
            if (window.kakao && window.kakao.maps) {
                this.map.relayout();
                this.map.setBounds(this.mapbound, 50);
            }
        },
        load_kakaomap() {
            console.log("load_kakaomap");
            if (window.kakao && window.kakao.maps) {
                kakao = window.kakao;
                this.initMap();
            } else {
                const script = document.createElement("script");

                script.onload = () => {
                    kakao = window.kakao;
                    console.log("kakao loaded");
                    kakao.maps.load(() => this.initMap());
                };
                script.src = `//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=239b31f85b2214bf17aef2559506f940&libraries=services`;
                document.head.appendChild(script);
                console.log("script appended");
            }
        },

        // refreshMarker(devices) {
        //     if (kakao_retry_count++ > 10) {
        //         return;
        //     }

        //     if (!kakao || !kakao.maps || !kakao.maps.services || !kakao.maps.services.Geocoder) {
        //         setTimeout(() => this.refreshMarker(devices), 100);
        //         return;
        //     }

        //     this.devices = devices;
        //     this.target_marker_count = 0;
        //     this.current_marker_count = 0;
        //     devices.forEach((device) => {
        //         if (device.address) this.target_marker_count++;
        //     });
        //     devices.forEach((device) => {
        //         this.setMarkerCoord(device);
        //     });

        //     kakao_retry_count = 0;
        // },

        // 카카오 지도 초기화
        initMap() {
            console.log("initMap");
            const container = this.$refs.kakaomapcontainer;
            const options = {
                center: new kakao.maps.LatLng(33.450701, 126.570667), //카카오 지도 화면 기준점
                level: 5,
            };
            this.map = new kakao.maps.Map(container, options);

            this.destination = {
                address: "대구 수성구 달구벌대로 2435 두산위브제니스상가 301",
                nickname: "제니스뷔페 수성점",
                content: "제니스뷔페 수성점",
            };
            this.setMarkerCoord(this.destination);
            
        },

        startNavi()
        {
            console.log(kakao);
            var kakaonavi = new kakao.Navi();
            kakaonavi.start(this.destination_navi);
        },



        setMarkerCoord(device) {
            if (!device.address) return;
            console.log(kakao);
            var geocoder = new kakao.maps.services.Geocoder();
            console.log(device);
            geocoder.addressSearch(device.address, (result, status) => {
                console.log(result);
                if (status == kakao.maps.services.Status.OK) {
                    var coords = new kakao.maps.LatLng(Number(result[0].y), Number(result[0].x));
                    this.destination_navi = {
                        name : "제니스뷔페 수성점",
                        x : Number(result[0].x),
                        y : Number(result[0].y),
                        coordType : 'wgs84',
                    }
                    var marker = new kakao.maps.Marker({
                        title: device.sn,
                        map: this.map,
                        position: coords,
                    });

                    var content = '<div class="customoverlay"><div class="nickname">' + device.nickname + "</div></div>";

                    // 커스텀 오버레이를 생성합니다
                    var customOverlay = new kakao.maps.CustomOverlay({
                        map: this.map,
                        position: coords,
                        content: content,
                        yAnchor: 0,
                    });
                    this.current_marker_count++;

                    if (this.current_marker_count == 1) this.mapbound = new kakao.maps.LatLngBounds(coords, coords);
                    else if (this.current_marker_count > 1) this.mapbound.extend(coords);

                    this.map.setBounds(this.mapbound, 1);
                    this.map.setLevel(4);
                }
            });
        },

        setBound(address, padding = 50) {
            var geocoder = new kakao.maps.services.Geocoder();
            geocoder.addressSearch(address, (result, status) => {
                if (status == kakao.maps.services.Status.OK) {
                    var coords = new kakao.maps.LatLng(Number(result[0].y), Number(result[0].x));
                    this.mapbound = new kakao.maps.LatLngBounds(coords, coords);
                    this.map.setBounds(this.mapbound, padding);
                }
            });
        },
    },

    data() {
        return {
            // 작동확인을 위한 수동 좌표 지정
            map: null,
            devices: null,
            mapbound: null,
            target_marker_count: 0,
            current_marker_count: 0,
        };
    },
};
</script>
<style lang="scss">
.customoverlay {
    position: relative;
    bottom: 85px;
    // border-radius: 6px;
    // border: 1px solid #ccc;
    // border-bottom: 2px solid #ddd;
    float: left;
    background: #fff;
    opacity: 1;
    padding: 5px;
    div.nickname {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
    }
    div.sn {
        font-size: 10px;
    }
}
.customoverlay:nth-of-type(n) {
    border: 0;
    box-shadow: 0px 1px 2px #888;
}
.customoverlay .title {
    display: block;
    text-align: center;
    padding: 10px 15px;
    font-size: 14px;
    font-weight: bold;
}
.customoverlay:after {
    content: "";
    position: absolute;
    margin-left: -12px;
    left: 50%;
    bottom: -12px;
    width: 22px;
    height: 12px;
    background: url("https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/vertex_white.png");
}
</style>
<style lang="scss" scoped>
div#kakaomapcontainer {
    border: 2px solid #999;
    border-radius: 10px;
    width: 100%;
    height: 100%;
}
</style>
