<template>
    <div id="page_greeting" class="page">
        <div class="page_background">
        </div>
        <div id="page_intro_content">
            <div id="text_container1">
                <div id="pic1" />
                <div id="text1">
                    <p>여러분의 사랑을 듬뿍 받으며 자라고 있는<br/>선겸이가 첫돌을 맞았습니다.</p>
                    <p>하루가 다르게 애교와 장난이 늘어가는 선겸이 덕분에 저희 가정에 웃음이 끊이지 않습니다.
                    이 모두가 애정과 사랑으로 지켜보고 도와주신 여러분 덕분입니다.</p>
                    <p>그동안 도움을 주신 여러분들과 가족, 친지들에게 감사의 마음을 전하고, 선겸이의 첫 생일을 함께 축하하는 자리를 마련하였습니다.</p>
                    <p>바쁘시더라도 꼭 참석해 주셔서 선겸이의 첫 돌을 축하해 주신다면 저희들에게 큰 영광일 것입니다.</p>
                    <p>항상 감사드립니다.</p>
                </div>
                <div id="pic_welcome" />
            </div>
            <!-- <div id="img_sg_main" /> -->
        </div>
    </div>
</template>
<script>
import $ from "jquery";

export default {
    mounted() {

    },
};
</script>
<style lang="scss" scoped>
#page_greeting {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 100vw;
    // background-color: #f3d2cb;
    background-image: url("@/assets/images/background_pattern3.png");
    background-size: 50%;
    #page_intro_content {
        margin-top : 30px;
        width: 100vw;
        top: 0;
        left: 0;
        z-index: 1;
    }
    #page_intro_background {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100vw;
        height: 100vh;
        z-index: 0;
        overflow-y: hidden;
        .balloon {
            position: absolute;
            left: 30px;
            top: 110vh;
            width: 20vw;
        }
    }
    #text_container1 {
        margin-left: auto;
        margin-right: auto;
        width: 90vw;
        flex-direction: column;
        font-family: "Dongle", sans-serif;


        #pic1 {
            margin-top: 30px;
            margin-left: auto;
            margin-right: auto;
            background-image: url("@/assets/images/sg_image2.png");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            width: 90vw;
            height: 60vw;
            border-radius: 2vw;
            border: 5px solid #fff;
            filter: drop-shadow(0 0 10px #0008);
            margin-bottom: 20px;
        }

        #text1 {
            font-family: "Notosans", sans-serif;
            text-align: left;
            font-size: 16px;
            line-height: 1.5;
            font-weight: 600;
            filter: drop-shadow(0px 0px 2px #fff);
            width: 100%;
            padding-left : 10px;
            padding-right : 10px;
            p {
                text-align: center;
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }

        #pic_welcome {
            margin-top: 10px;
            margin-left: auto;
            margin-right: auto;
            background-image: url("@/assets/images/welcome.png");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            width: 90vw;
            height: 35vw;
            filter: drop-shadow(0 0 10px #0008);
        }
    }

    #img_sg_main {
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        background-image: url("@/assets/images/sg_main.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 85vw;
        height: 85vw;
        border-radius: 45vw;
        border: 5px solid #fff;
        filter: drop-shadow(0 0 10px #0008);
    }
}
</style>
